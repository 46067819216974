<template>
  <div>
    <div class="full-height">
      <v-row justify="center" style="height: 90%">
        <v-col cols="11">
          <v-card class="pa-6 my-4" width="100%" raised height="100%">
            <div class="d-flex align-center">
              <v-icon color="primary" @click="onClickPrevBtn()"
                >arrow_back</v-icon
              >
              <div class="pl-2 text-h5 primary--text font-weight-medium">
                Additional Details
              </div>
            </div>
            <v-form>
              <ValidationObserver ref="dynamicFieldValuesForm">
                <v-row class="ma-4">
                  <v-col
                    v-for="field in selectedItem"
                    :key="field.Custom_Field_Id"
                    cols="12"
                    md="4"
                    sm="6"
                  >
                    <!-- Single Choice / Multiple Choice -->
                    <ValidationProvider
                      v-if="
                        field.Custom_Field_Type.toLowerCase() ===
                          'single choice' ||
                        field.Custom_Field_Type.toLowerCase() ===
                          'multiple choice'
                      "
                      :ref="`Custom Select` + field.Custom_Field_Id"
                      v-slot="{ errors }"
                      :name="field.Custom_Field_Name"
                      :rules="field.Mandatory === 'Yes' ? 'required' : ''"
                    >
                      <v-autocomplete
                        v-model="field.Value"
                        :ref="`Custom Select` + field.Custom_Field_Id"
                        outlined
                        :items="parseDropdownItems(field.Dropdown_Values)"
                        :multiple="
                          field.Custom_Field_Type.toLowerCase() ===
                          'multiple choice'
                        "
                        clearable
                        @input="onChangeFields($event, field.Value)"
                        :error-messages="errors"
                      >
                        <template v-slot:label>
                          {{ field.Custom_Field_Name }}
                          <span
                            v-if="field.Mandatory === 'Yes'"
                            style="color: red"
                            >*</span
                          ></template
                        >
                      </v-autocomplete>
                    </ValidationProvider>

                    <!-- URL Link -->
                    <v-btn
                      v-else-if="
                        field.Custom_Field_Type.toLowerCase() === 'url'
                      "
                      @click="urlRedirection(field?.Url_Link)"
                      text
                      color="primary"
                      class="text-subtitle-1 font-weight-regular text-decoration-underline ml-n4"
                    >
                      {{
                        field.Custom_Field_Name ? field.Custom_Field_Name : ""
                      }}
                    </v-btn>

                    <!-- Number Field -->
                    <ValidationProvider
                      v-else-if="
                        field.Custom_Field_Type.toLowerCase() === 'number'
                      "
                      :ref="`${field.Custom_Field_Id}`"
                      v-slot="{ errors }"
                      :name="field.Custom_Field_Name"
                      :rules="
                        field.Mandatory.toLowerCase() === 'yes'
                          ? `required|numeric|minimum:${field.Min_Validation}|maximum:${field.Max_Validation}`
                          : `numeric|minimum:${field.Min_Validation}|maximum:${field.Max_Validation}`,
                      "
                    >
                      <v-text-field
                        v-model="field.Value"
                        outlined
                        type="number"
                        clearable
                        :error-messages="errors"
                        @input="onChangeFields($event, field.Value)"
                      >
                        <template v-slot:label>
                          {{ field.Custom_Field_Name }}
                          <span
                            v-if="field.Mandatory.toLowerCase() === 'yes'"
                            style="color: red"
                            >*</span
                          ></template
                        >
                      </v-text-field>
                    </ValidationProvider>

                    <!-- Text Area -->
                    <ValidationProvider
                      v-else-if="
                        field.Custom_Field_Type.toLowerCase() === 'text area'
                      "
                      :ref="`${field.Custom_Field_Id}`"
                      v-slot="{ errors }"
                      :name="field.Custom_Field_Name"
                      :rules="
                      field.Mandatory.toLowerCase() === 'yes'
                          ? `required|min:${parseInt(field.Min_Validation)}|max:${parseInt(field.Max_Validation)}|regex:${field.Validation_Id}`
                          : `min:${parseInt(field.Min_Validation)}|max:${parseInt(field.Max_Validation)}|regex:${field.Validation_Id}`,
                      "
                    >
                      <v-textarea
                        v-model="field.Value"
                        outlined
                        rows="1"
                        clearable
                        :counter="field.Max_Validation"
                        :maxlength="field.Max_Validation"
                        :error-messages="errors"
                        @input="onChangeFields($event, field.Value)"
                      >
                        <template v-slot:label>
                          {{ field.Custom_Field_Name }}
                          <span
                            v-if="field.Mandatory.toLowerCase() === 'yes'"
                            style="color: red"
                            >*</span
                          ></template
                        >
                      </v-textarea>
                    </ValidationProvider>

                    <!-- Date -->
                    <ValidationProvider
                      v-else-if="
                        field.Custom_Field_Type.toLowerCase() === 'date'
                      "
                      :ref="`date` + field.Custom_Field_Id"
                      v-slot="{ errors }"
                      :name="field.Custom_Field_Name"
                      :rules="
                            field.Mandatory.toLowerCase() === 'yes'
                              ? 'required'
                              : '',
                          "
                    >
                      <v-text-field
                        v-model="field.Value"
                        type="date"
                        outlined
                        clearable
                        :error-messages="errors"
                        @input="onChangeFields($event, field.Value)"
                      >
                        <template v-slot:label>
                          {{ field.Custom_Field_Name }}
                          <span
                            v-if="field.Mandatory.toLowerCase() === 'yes'"
                            style="color: red"
                            >*</span
                          >
                        </template>
                      </v-text-field>
                    </ValidationProvider>

                    <!-- Text Field -->
                    <ValidationProvider
                      v-else-if="
                        field.Custom_Field_Type.toLowerCase() === 'text field'
                      "
                      :ref="`${field.Custom_Field_Id}`"
                      v-slot="{ errors }"
                      :name="field.Custom_Field_Name"
                      :rules="
                        field.Mandatory.toLowerCase() === 'yes'
                          ? `required|min:${parseInt(field.Min_Validation)}|max:${parseInt(field.Max_Validation)}|regex:${field.Validation_Id}`
                          : `min:${parseInt(field.Min_Validation)}|max:${parseInt(field.Max_Validation)}|regex:${field.Validation_Id}`,
                      "
                    >
                      <v-text-field
                        v-model="field.Value"
                        outlined
                        clearable
                        :counter="field.Max_Validation"
                        :maxlength="field.Max_Validation"
                        :error-messages="errors"
                        @input="onChangeFields($event, field.Value)"
                      >
                        <template v-slot:label>
                          {{ field.Custom_Field_Name }}
                          <span
                            v-if="field.Mandatory?.toLowerCase() === 'yes'"
                            style="color: red"
                            >*</span
                          ></template
                        >
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </ValidationObserver>
            </v-form>
            <v-divider></v-divider>
            <div class="mt-3 d-flex justify-end">
              <v-btn
                color="primary"
                style="border-radius: 10px"
                large
                :loading="saveLoading || listLoading"
                @click="validateAddEdit()"
                >Save</v-btn
              >
            </div>
            <v-overlay
              absolute
              :value="saveLoading || listLoading"
              color="#fff"
              z-index="1"
            >
              <v-progress-circular
                color="primary"
                indeterminate
                size="50"
              ></v-progress-circular>
            </v-overlay> </v-card
        ></v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { ADD_UPDATE_CUSTOM_FIELD_VALUES } from "@/graphql/OnboardingQueries";
import { scrollScreenToTop } from "../../../helper";
import moment from "moment";

export default {
  name: "DynamicCustomFields",

  data: () => ({
    isUpdate: false,
    isManualFundAddress: false,
    // custom fields
    saveLoading: false,
    isFormDirty: false,
    selectedItem: [],
  }),
  props: {
    listCustomFields: {
      type: Array,
      default: () => [],
    },
    listLoading: {
      type: Boolean,
      default: false,
    },
    candidateId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    formatDate() {
      return (date) => {
        return date && moment(date).isValid()
          ? moment(date).format("DD/MM/YYYY")
          : "";
      };
    },
  },
  emits: ["back-to-prev", "updated-dynamic-form"],

  mounted() {
    scrollScreenToTop();
    this.selectedItem = this.listCustomFields;
    this.$emit("updated-dynamic-form");
  },

  methods: {
    onClickPrevBtn() {
      this.$emit("back-to-prev");
    },
    onChangeFields() {
      this.isFormDirty = true;
    },
    parseDropdownItems(value) {
      return JSON.parse(value);
    },
    validateAddEdit() {
      let vm = this;
      vm.$refs.dynamicFieldValuesForm.validate().then((isValid) => {
        let invalidFields = [];
        Object.keys(this.$refs).forEach((refName) => {
          const field = this.$refs[refName];
          if (field && field.errors && field.errors.length > 0) {
            invalidFields.push(refName);
          }
        });
        if (invalidFields.length > 0) {
          let firstField = this.$refs[invalidFields[0]];
          firstField.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
        if (isValid) {
          vm.saveLoading = true;
          vm.addUpdateFormStructure();
        }
      });
    },
    addUpdateFormStructure() {
      let fieldValues = this.selectedItem.reduce((acc, field) => {
        let fieldValue = field.Value;

        // Handle the "Date" type
        if (field.Custom_Field_Type?.toLowerCase() === "date") {
          fieldValue = moment(fieldValue).isValid()
            ? moment(fieldValue).format("YYYY-MM-DD")
            : null;
        }

        acc[`${field.Custom_Field_Id}`] = fieldValue;
        return acc;
      }, {});
      fieldValues = JSON.stringify(fieldValues);
      this.addUpdateCustomFields(fieldValues);
    },
    addUpdateCustomFields(fieldValues = {}) {
      let vm = this;
      vm.$apollo
        .mutate({
          mutation: ADD_UPDATE_CUSTOM_FIELD_VALUES,
          variables: {
            Form_Id: 178,
            Primary_Id: this.candidateId,
            Field_Value: fieldValues,
          },
          client: "apolloClientE",
        })
        .then(() => {
          vm.$emit("updated-dynamic-form");
          vm.$emit("additional-saved");
          vm.saveLoading = false;
        })
        .catch((addErr) => {
          let snackbarData = {
            isOpen: true,
            message:
              addErr.graphQLErrors.length !== 0
                ? addErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
        });
    },
    urlRedirection(redirectionPath) {
      if (redirectionPath) {
        window.open(redirectionPath, "_blank");
      }
    },
    showAlert(snackbarData) {
      this.$store.commit("OPEN_SNACKBAR", snackbarData);
    },
  },
};
</script>
